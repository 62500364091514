.about-section {
  padding: 0 2.5rem;
}

.about-section p {
  color: var(--slate);
  margin-bottom: 2rem;
}

.about-section .my-stack {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about-section .stack-list p {
  font-size: 1.3rem;
}

.about-section .stack-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--slate);
  height: 3rem;
}

.about-section span {
  color: var(--green);
  margin-bottom: 1.6rem;
  margin-right: 1.6rem;
}

.about-section .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8rem;
}

.about-section .my-image-container {
  position: relative;
  width: 23.8rem;
  height: 23.8rem;
  margin-top: 4rem;
  z-index: -3;
}

.about-section .my-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  background-color: #004d7a;
}

.about-section .shade {
  content: "";
  position: absolute;
  width: 23.8rem;
  height: 23.8rem;
  left: 0;
  top: 0;
  border-radius: 8px;
  z-index: 2;
  background-image: linear-gradient(to right top, #0519376e, #004d7a7f, #0087937b, #00bf737c, #a6eb1284);
}

.about-section .shade.hidden {
  display: none;
}

.about-section .frame {
  content: "";
  position: absolute;
  width: 23.8rem;
  height: 23.8rem;
  left: 10%;
  top: 10%;
  border: 2px solid var(--green);
  border-radius: 6px;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.about-section .frame.resize {
  left: 7%;
  top: 7%;
}

@media screen and (min-width: 500px) {
  .about-section {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 820px) {
  .about-section {
    padding: 0 10rem;
  }

  .about-section .about-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .about-section .about-content {
    width: 58%;
    margin-right: 5rem;
  }

  .about-section .wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 22.8rem;
    margin-top: 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .about-section .my-image-container {
    margin-top: 0;
  }

  .about-section .shade,
  .about-section .frame,
  .about-section .my-image-container {
    width: 22.8rem;
    height: 22.8rem;
  }
}

@media screen and (min-width: 1280px) {
  .about-section {
    padding: 0 22rem;
  }

  .about-section .wrapper {
    width: 30rem;
    height: 30rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .about-section .shade,
  .about-section .frame,
  .about-section .my-image-container {
    width: 30rem;
    height: 30rem;
  }
}
