.loader {
    width: 100%;
    height: 100%;
    background-color: var(--navy);
    z-index: 100;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-logo {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 6rem;
    font-weight: 600;
    width: 8rem;
    height: 8rem;
    border-radius: 8rem;
    background-color: var(--green);
    color: var(--navy);
}

.loader-logo::after {
    content: "";
    background-image: conic-gradient(
        var(--green) 20deg,
        transparent 120deg
    );
    width: 110%;
    height: 110%;
    border-radius: 100%;
    position: absolute;
    animation: rotate 2s linear infinite;
    z-index: -120;
}

.loading {
    background-color: var(--navy);
    color: var(--green);
}

.loader-logo div {
    margin-right: 2px;
    font-weight: 400;
}

.loader-logo span {
    position: absolute;
    font-size: 2.4rem;
    font-weight: 600;
    right: 9%;
    top: 6%;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
