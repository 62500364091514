.section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 4rem 0;
}

.section-header .section-label {
  font-family: var(--font-fira);
  color: var(--green);
  font-size: 2rem;
  margin-bottom: -2px;
}

.section-header .section-title {
  font-size: 2.6rem;
  color: var(--lightest-slate);
  margin-left: 2px;
  margin-right: 2rem;
}

.section-header .section-decor {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  background-color: var(--lightest-navy);
  margin-top: 1.3rem;
}

@media screen and (min-width: 769px) {
  .section-header .section-decor {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 20rem;
  }
}

@media screen and (min-width: 1280px) {
  .section-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 18rem 0 4rem 0;
  }

  .section-header .section-decor {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 30rem;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .section-header .section-label {
    font-size: 1.4rem;
  }

  .section-header .section-title {
    font-size: 1.8rem;
  }
}
