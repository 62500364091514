.footer {
  padding: 5rem 2.5rem;
  padding-bottom: 2rem;
  text-align: center;
}

.footer .social-icons {
  margin-bottom: 3rem;
}

.footer a {
  margin-right: 3.5rem;
}

.footer p {
  font-family: var(--font-fira);
  font-size: 1.2rem;
}

@media screen and (min-width: 820px) {
  .footer .social-icons {
    display: none;
  }
}
